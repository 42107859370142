<template>
  <div class="view-home news-list-block">
    <div class="container">
      <nav class="breadcrumbs-block">
        <ul class="breadcrumbs-list">
          <li class="breadcrumbs-item"><router-link to="/">{{ $t('navbar.home') }}</router-link></li>
          <li class="breadcrumbs-item">Родителям</li>
        </ul>
      </nav>
      <section class="news-main" id="parentsList">
        <div class="container news-list-desktop">
          <div class="section-title" style="margin-top: 0">
            Колонка родителя
          </div>
          <div class="subtitle">
            Читайте наши статьи и узнайте больше о важных темах для родителей.
          </div>
          <div class="row">
            <div class="news-list-wrapper">
              <router-link v-for="(item, index) in parentsListLeft" :to="{ name: 'parents-list', params: { id: parentsList[index].id }}">
                <div class="news-left-img">
                  <el-image
                      style="width: 100%; height: 100%; object-position: center;"
                      :src="item.image_path"
                      fit="cover"></el-image>
                </div>
                <div class="heading">
                  news
                </div>
                <div class="news-left-title truncate-title">
                  {{ item[`title_${$i18n.locale}`] || item.title_ru}}
                </div>
                <div class="news-left-text truncate-text">
                  {{ item[`description_${$i18n.locale}`] || item.description_ru}}
                </div>
              </router-link>
            </div>

          </div>
          <nav class="seminars-pagination" v-if="parentsList.length > 15">
            <ul class="pagination">
              <li class="page-item">
                <a class="page-link" href="#">
                  <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M5.61188 10.875L6.875 9.61188L2.77208 5.5L6.875 1.38813L5.61187 0.125L0.236874 5.5L5.61188 10.875Z"
                        fill="black"/>
                  </svg>
                </a>
              </li>
              <li class="page-item"><a class="page-link active" href="#">1</a></li>
              <li class="page-item"><a class="page-link" href="#">2</a></li>
              <li class="page-item"><a class="page-link" href="#">3</a></li>
              <li class="page-item">
                <a class="page-link" href="#">
                  <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M1.73187 0.125L0.46875 1.38812L4.57167 5.5L0.46875 9.61187L1.73187 10.875L7.10688 5.5L1.73187 0.125Z"
                        fill="black"/>
                  </svg>
                </a
                ></li>
            </ul>
          </nav>
        </div>

      </section>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      parentsList: [],
      parentsListLeft: [],
      parentsListRight: [],
    }
  },
  methods: {

  },
  mounted() {
    this.$http.get(window.API_TEST + '/api/parents/list')
        .then(res => {
          this.parentsList = res.body.data
          this.parentsList.sort( (a, b) => {
            // сортирую, чтобы вначале шли свежие новости
            let dateA = new Date(a.created_at);
            let dateB = new Date(b.created_at);
            return dateB - dateA;
          })

          this.parentsListLeft = this.parentsList.slice(0, 5)
          this.parentsListRight = this.parentsList.slice(5, 15)

        })
  }


}

</script>

<style>

.item-block {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 40px;
  padding-bottom: 40px;
}

.item-block .el-image {
  border-radius: 10px;
}

.item-block .title {
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: #343f68;
}

.item-block .description {
  color: #696984;
  font-size: 16px;
}

.news-list-block .breadcrumbs-block {
  margin: 30px 0;
}

.news-list-block .breadcrumbs-block li {
  color: #343f68;
}

.news-list-block .breadcrumbs-block ul li {
  border-right: 1px solid #343f68;
}

.news-list-block .breadcrumbs-block ul li:last-child {
  border-right: none;
}

.news-list-block .breadcrumbs-block a {
  color: #343f68;
}


</style>
